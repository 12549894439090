import React, { useEffect, useState } from "react";
import {
  Alert,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import AccountSelection from "./AccountSelection";
import PencilIcon from "../../../../assets/images/pencil2.svg";
import DeleteIcon from "../../../../assets/images/delete.svg";
import AddIcon from "../../../../assets/images/add-icon4.svg";
import ArrowDownIcon from "../../../../assets/images/arrow-down2-teal.svg";
import useDashboard from "src/components/Dashboard/useDashboard";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  atm_editGoal,
  atm_financialData,
  atm_financialTextData,
  atm_financialTextDataSelector,
  atm_selectedDetailsData,
  atm_selectedGeneralData,
} from "src/state/atomDashboard";
import { usdFormate, usdUnformat, yearFrom, yearTo } from "src/global/central";
import { NumericFormat } from "react-number-format";
import {
  isValidGoalMonth,
  validateYear,
  validateYearOnly,
} from "src/validations/Goals";
import { getGoalStartDate } from "src/utils/getGoalStartDate";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const Financial = () => {
  const { dataDashboard, fnsDashboard } = useDashboard();

  const isMobile = useMediaQuery("(max-width: 750px)");

  const [data, setData] = useRecoilState(atm_financialData);

  const [totalContribution, setTotalContribution] = useState(0);

  const [financialTextData, setFinancialTextData] = useRecoilState(
    atm_financialTextData
  );

  const financialTextDataSelector = useRecoilValue(
    atm_financialTextDataSelector
  );

  const isEditMode = useRecoilValue(atm_editGoal);

  const selectedGeneralData = useRecoilValue(atm_selectedGeneralData);
  const selectedDetailsData = useRecoilValue(atm_selectedDetailsData);

  const [activeRow, setActiveRow] = useState(null);

  useEffect(() => {
    if (!financialTextData.total) {
      fnsDashboard.getCostProjection();
    }
    if (!data.length && !isEditMode) {
        setData([
          {
            selectedAccountId: "",
            startingBalance: 0,
            monthlyAddition: 0,
            startMonth: getGoalStartDate(selectedGeneralData.month, selectedGeneralData.year).startMonth,
            startYear: getGoalStartDate(selectedGeneralData.month, selectedGeneralData.year).startYear,
            endMonth: selectedGeneralData.month,
            endYear: selectedGeneralData.year,
            contribution: 0,
            status: 0,
          },
        ]);
    }
  }, []);

  const addData = () => {
    setData([
      ...data,
      {
        selectedAccountId: "",
        startingBalance: 0,
        monthlyAddition: 0,
        startMonth: getGoalStartDate(selectedGeneralData.month, selectedGeneralData.year).startMonth,
        startYear: getGoalStartDate(selectedGeneralData.month, selectedGeneralData.year).startYear,
        endMonth: selectedGeneralData.month,
        endYear: selectedGeneralData.year,
        contribution: 0,
        status: 0,
      },
    ]);
  };

  const removeData = (index) => {
    setData((accounts) =>
      accounts.map((account, i) =>
        i === index ? { ...account, status: 1 } : account
      )
    );
  };

  const calculateTotalContribution = () => {
    const total = data
      .filter((val) => val.status === 0)
      .reduce((acc, val) => acc + (parseInt(val.contribution, 10) || 0), 0);

    setTotalContribution(total);
  };

  useEffect(() => {
    calculateTotalContribution();
  }, [data]);

  const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, negative, ...other } = props;
    
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix={"$ "}
        allowNegative={props.name !== "monthlyAddition"}
      />
    );
  });

  const PercentFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, onBlur, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        onBlur={(e) => {
          const inputValue = e.target.value;
          if (inputValue.startsWith("0") && parseFloat(inputValue) > 0) {
            e.target.value = inputValue.replace(/^0+/, "");
          }

          onBlur({
            target: {
              name: props.name,
              value: inputValue.replace(/^0+/, "")
            }
          })
        }}
        decimalScale={2}
        allowNegative={false}
        allowLeadingZeros={false}
        valueIsNumericString
        suffix={" %"}
        isAllowed={(values) => {
          const { floatValue } = values;
          
          return floatValue === undefined || floatValue <= 999.99;
        }}
      />
    );
  });

  const handleFieldChange = (e, index, field) => {
    let newData = [...data];
    let value = e.target.value;

    let currentYear = new Date().getFullYear();
    let currentMonth = new Date().getMonth() + 1;

    let { startYear, endYear, startMonth, endMonth } = newData[index];

    if (field == "startYear") {
      if (value == endYear) {
        newData[index] = {
          ...newData[index],
          startMonth: endMonth,
          [field]: value,
        };
      } else if (currentYear == value) {
        newData[index] = {
          ...newData[index],
          startMonth: currentMonth,
          [field]: value,
        };
      } else {
        newData[index] = {
          ...newData[index],
          [field]: value,
        };
      }
    } else if (field == "endYear") {
      if (value == startYear) {
        newData[index] = {
          ...newData[index],
          endMonth: startMonth,
          [field]: value,
        };
      } else if (currentYear == value) {
        newData[index] = {
          ...newData[index],
          endMonth: currentMonth,
          [field]: value,
        };
      } else {
        newData[index] = {
          ...newData[index],
          [field]: value,
        };
      }
    } else {
      newData[index] = {
        ...newData[index],
        [field]: value,
      };
    }
    setData(newData);
    let { selectedAccountId } = newData[index];
    if (selectedAccountId) {
      fnsDashboard.calculateAccountContribution(newData[index], index);
    }
  };

  const handleToggle = (index) => {
    if (activeRow === index) {
      setActiveRow(null);
      return;
    }
    setActiveRow(index);
  };

  return (
    <div className="financialBox">
      {!isMobile && (
        <>
          <div className="head">Financial Information</div>
          <div className="subHead">
            Let's take care of the finances for your goal
          </div>

          <div className="hr"></div>
        </>
      )}

      <div className="insideContent">
        {selectedGeneralData.option ? (
          <span className="text">
            <span>
              <div className="bullet mob"></div>
              Out of total college fees of
              <TextField
                id="totalInput"
                className="amountInput totalInput editable"
                variant="standard"
                defaultValue={financialTextData.total}
                onBlur={(e) =>
                  setFinancialTextData((prevData) => ({
                    ...prevData,
                    total: usdUnformat(e.target.value),
                  }))
                }
                sx={{
                  width: 118,
                  mx: 3,
                  "& .MuiInputBase-root": {
                    color: "#0c89ef",
                  },
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  endAdornment: (
                    <InputAdornment className="dollar" position="start">
                      <img className="pencilIcon" src={PencilIcon} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
            </span>
            <span>
              <div className="bullet mob"></div>
              <span className="pc">,</span> I want to contribute
              <TextField
                id="contributionAmount"
                className="savingsInput contributionInput editable"
                variant="standard"
                defaultValue={financialTextData.savings}
                onBlur={(e) =>
                  setFinancialTextData({
                    ...financialTextData,
                    savings: usdUnformat(e.target.value),
                  })
                }
                sx={{
                  width: 118,
                  mx: 3,
                  "& .MuiInputBase-root": {
                    color: "#0c89ef",
                  },
                }}
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  endAdornment: (
                    <InputAdornment position="start" className="dollar">
                      <img className="pencilIcon" src={PencilIcon} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
              from my savings,
            </span>
            <span>
              <div className="bullet mob"></div>
              <TextField
                id="scholarshipsInput"
                className="amountInput scholarshipsInput editable"
                variant="standard"
                sx={{
                  width: 118,
                  mx: 3,
                  "& .MuiInputBase-root": {
                    color: "#0c89ef",
                  },
                }}
                defaultValue={financialTextData.scholarships}
                onBlur={(e) =>
                  setFinancialTextData({
                    ...financialTextData,
                    scholarships: usdUnformat(e.target.value),
                  })
                }
                InputProps={{
                  inputComponent: NumericFormatCustom,
                  endAdornment: (
                    <InputAdornment position="start" className="dollar">
                      <img className="pencilIcon" src={PencilIcon} alt="" />
                    </InputAdornment>
                  ),
                }}
              />
              from scholarships, and remaining
            </span>
            <span>
              <div className="bullet mob"></div>
              <TextField
                aria-readonly
                id="loansInput"
                className="amountInput loansInput read-only"
                variant="standard"
                sx={{
                  width: isMobile ? "80px !important" : 100,
                  mx: 3,
                  "& .MuiInput-underline:after": {
                    border: "none",
                  },
                  "&:hover .MuiInput-underline:before": {
                    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  },
                }}
                defaultValue={financialTextDataSelector.remaining}
                onBlur={(e) =>
                  setFinancialTextData({
                    ...financialTextData,
                    remaining: e.target.value,
                  })
                }
                InputProps={{
                  readOnly: true,
                  inputComponent: NumericFormatCustom,
                }}
              />
              with different types of loans
            </span>
          </span>
        ) : (
          <span className="text">
            <p>
              I want to follow below contribution plan to fund my goal amount of
              <TextField
                aria-readonly
                id="loansInput"
                className="amountInput loansInput read-only"
                variant="standard"
                sx={{
                  width: isMobile ? "88px !important" : 110,
                  mx: 0,
                  ml: "10px",
                  "& .MuiInput-underline:after": {
                    border: "none",
                  },
                  "&:hover .MuiInput-underline:before": {
                    borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                  },
                }}
                value={selectedDetailsData.amount}
                InputProps={{
                  readOnly: true,
                  inputComponent: NumericFormatCustom,
                }}
              />
            </p>
          </span>
        )}
        {isMobile && (
          <div className="mob-total">
            <span className="head">Total</span>
            <span className="value">{usdFormate(totalContribution)}</span>
          </div>
        )}
        <div className="financial">
          <div className="financial-inside scroller">
            <div className="tableCover">
              {!isMobile ? (
                <TableContainer
                  sx={{ position: "absolute", maxHeight: "100%" }}
                >
                  <Table stickyHeader className="goalsFinancialTable">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <div className="add">
                            <img onClick={addData} src={AddIcon} alt="" />
                          </div>
                        </TableCell>
                        <TableCell className="account">Account</TableCell>
                        <TableCell className="starting-balance">
                          Starting Balance
                        </TableCell>
                        <TableCell className="monthly-addition">
                          Monthly Addition
                        </TableCell>
                        <TableCell className="rate-of-return">
                          Rate Of Return
                        </TableCell>
                        <TableCell className="start-date">Start Date</TableCell>
                        <TableCell className="start-date">End Date</TableCell>

                        <TableCell className="contribution">
                          Contribution
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {data.map((val, index) => {
                        return (
                          <TableRow
                            sx={{
                              "&.MuiTableRow-root": {
                                display: val.status ? "none" : "table-row",
                              },
                            }}
                            key={index}
                          >
                            <TableCell className="deleteBtn-coloumn">
                              <img
                                onClick={() => removeData(index)}
                                height={20}
                                className="deleteIcon"
                                src={DeleteIcon}
                                alt=""
                              />
                            </TableCell>
                            <TableCell className="account">
                              <AccountSelection eachData={val} index={index} />
                            </TableCell>
                            <TableCell className="starting-balance">
                              <TextField
                                className="editable"
                                variant="standard"
                                disabled={!val.selectedAccountId}
                                defaultValue={val.startingBalance}
                                onBlur={(e) => {
                                  if (e.target.value == val.startingBalance) {
                                    return;
                                  } else if (e.target.value === "") {
                                    e.target.value = 0;
                                  }
                                  handleFieldChange(
                                    e,
                                    index,
                                    "startingBalance"
                                  );
                                }}
                                sx={{ width: 100 }}
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  endAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className="dollar"
                                    >
                                      <img
                                        className="pencilIcon"
                                        src={PencilIcon}
                                        alt=""
                                      />
                                    </InputAdornment>
                                  ),
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="monthly-addition">
                              <TextField
                                className="editable"
                                variant="standard"
                                disabled={!val.selectedAccountId}
                                defaultValue={val.monthlyAddition}
                                onBlur={(e) => {
                                  if (e.target.value == val.monthlyAddition) {
                                    return;
                                  } else if (e.target.value === "") {
                                    e.target.value = 0;
                                  }
                                  handleFieldChange(
                                    e,
                                    index,
                                    "monthlyAddition"
                                  );
                                }}
                                sx={{ width: 100 }}
                                name="monthlyAddition"
                                InputProps={{
                                  inputComponent: NumericFormatCustom,
                                  name: "monthlyAddition",
                                  endAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className="dollar"
                                    >
                                      <img
                                        className="pencilIcon"
                                        src={PencilIcon}
                                        alt=""
                                      />
                                    </InputAdornment>
                                  ),
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="rate-of-return">
                              <TextField
                                className="editable"
                                variant="standard"
                                disabled={!val.selectedAccountId}
                                defaultValue={val.rateOfReturn || 0}
                                onBlur={(e) => {
                                  if (e.target.value == val.rateOfReturn) {
                                    return;
                                  } else if (e.target.value === "") {
                                    e.target.value = 0;
                                  }
                                  handleFieldChange(e, index, "rateOfReturn");
                                }}
                                inputMode="decimal"
                                sx={{
                                  width: 85,
                                  input: {
                                    textAlign: "center",
                                  },
                                }}
                                InputProps={{
                                  inputComponent: PercentFormatCustom,
                                  endAdornment: (
                                    <InputAdornment
                                      position="start"
                                      className="dollar"
                                    >
                                      <img
                                        className="pencilIcon"
                                        src={PencilIcon}
                                        alt=""
                                      />
                                    </InputAdornment>
                                  ),
                                  disableUnderline: true,
                                }}
                              />
                            </TableCell>
                            <TableCell className="start-date">
                              <div className="cell-content">
                                <FormControl
                                  variant="standard"
                                  size="small"
                                  sx={{ width: 55 }}
                                >
                                  {!val.startMonth && (
                                    <label
                                      id={`month-label-start-${index}`}
                                      className="monthLabel"
                                      style={{
                                        position: "absolute",
                                        top: -2,
                                        opacity: 0.8,
                                        color: "#858585",
                                      }}
                                    >
                                      MM
                                    </label>
                                  )}
                                  <Select
                                    value={val.startMonth}
                                    className="monthField"
                                    disabled={!val.selectedAccountId}
                                    labelId={`month-label-start-${index}`}
                                    onChange={(e) => {
                                      handleFieldChange(e, index, "startMonth");
                                    }}
                                  >
                                    {monthNames.map((month, index) => (
                                      <MenuItem key={index} value={index + 1} disabled={!isValidGoalMonth(index+1, "startMonth", val)}>
                                        {month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <Tooltip
                                  title={(() => {
                                    if (val.endYear) {
                                      return `Enter value b/w ${yearFrom} - ${val.endYear}`;
                                    } else {
                                      return `Enter value b/w ${yearFrom} - ${yearTo}`;
                                    }
                                  })()}
                                >
                                  <TextField
                                    placeholder="YYYY"
                                    variant="standard"
                                    defaultValue={val.startYear}
                                    disabled={!val.selectedAccountId}
                                    onChange={(e) => {
                                      validateYear(e, val.endYear, 0);
                                    }}
                                    onBlur={(e) => {
                                      if (e.target.value == val.startYear) {
                                        return;
                                      } else if (e.target.value === "") {
                                        e.target.style.color = "#000";
                                      } else if (
                                        !validateYearOnly(
                                          e.target.value,
                                          val.endYear,
                                          0
                                        )
                                      ) {
                                        e.target.value = val.startYear;
                                        e.target.style.color = "#000";
                                        return;
                                      }
                                      handleFieldChange(e, index, "startYear");
                                    }}
                                    sx={{ width: 55 }}
                                    size="small"
                                    type="tel"
                                    inputProps={{ maxLength: 4 }}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="cell-content">
                                <FormControl
                                  variant="standard"
                                  size="small"
                                  sx={{ width: 55 }}
                                >
                                  {!val.endMonth && (
                                    <label
                                      id={`month-label-end-${index}`}
                                      className="monthLabel"
                                      style={{
                                        position: "absolute",
                                        top: -2,
                                        opacity: 0.8,
                                        color: "#858585",
                                      }}
                                    >
                                      MM
                                    </label>
                                  )}
                                  <Select
                                    className="monthField endMonth"
                                    labelId={`month-label-end-${index}`}
                                    disabled={!val.selectedAccountId}
                                    value={val.endMonth}
                                    onChange={(e) => {
                                      handleFieldChange(e, index, "endMonth");
                                    }}
                                  >
                                    {monthNames.map((month, index) => (
                                      <MenuItem key={index} value={index + 1} disabled={!isValidGoalMonth(index+1, "endMonth", val)}>
                                        {month}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                <Tooltip
                                  title={(() => {
                                    if (val.startYear) {
                                      return `Enter value b/w ${val.startYear} - ${yearTo}`;
                                    } else {
                                      return `Enter value b/w ${yearFrom} - ${yearTo}`;
                                    }
                                  })()}
                                >
                                  <TextField
                                    placeholder="YYYY"
                                    variant="standard"
                                    defaultValue={val.endYear}
                                    disabled={!val.selectedAccountId}
                                    onChange={(e) => {
                                      validateYear(e, val.startYear, 1);
                                    }}
                                    onBlur={(e) => {
                                      if (e.target.value == val.endYear) {
                                        return;
                                      } else if (e.target.value === "") {
                                        e.target.style.color = "#000";
                                      } else if (
                                        !validateYearOnly(
                                          e.target.value,
                                          val.startYear,
                                          1
                                        )
                                      ) {
                                        e.target.value = val.endYear;
                                        e.target.style.color = "#000";
                                        return;
                                      }
                                      handleFieldChange(e, index, "endYear");
                                    }}
                                    type="tel"
                                    sx={{ width: 55 }}
                                    size="small"
                                    inputProps={{ maxLength: 4 }}
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                            <TableCell className="contribution">
                              <div className="contributionValue">
                                {usdFormate(val.contribution)}
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                      <TableRow className="totalRow">
                        <td></td>
                        <td>TOTAL</td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>{usdFormate(totalContribution)}</td>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <>
                  {data.map((val, index) => (
                    <div
                      hidden={val.status}
                      key={index}
                      className="financialTableRow"
                    >
                      <div className="headerRow">
                        <div className="col1 col">
                          <div className="row1 row">
                            <img
                              onClick={() => removeData(index)}
                              className="deleteIcon"
                              src={DeleteIcon}
                              alt=""
                            />
                            <span className="account-head mob-text">
                              Account
                            </span>
                          </div>
                          <div className="row2 row">
                            <span className="mob-accountSelection mob-text">
                              <AccountSelection eachData={val} index={index} />
                            </span>
                          </div>
                        </div>
                        <div
                          onClick={() => handleToggle(index)}
                          className="col2 col"
                        >
                          <img
                            style={{
                              transform:
                                activeRow === index ? "rotate(180deg)" : "",
                            }}
                            className="arrowDownLogo"
                            src={ArrowDownIcon}
                            alt=""
                          />
                        </div>
                      </div>
                      {activeRow === index && (
                        <div className="rowData">
                          <div className="head">Starting Balance</div>
                          <div className="data starting-balance">
                            <TextField
                              className="editable"
                              variant="standard"
                              defaultValue={val.startingBalance}
                              disabled={!val.selectedAccountId}
                              onBlur={(e) => {
                                if (e.target.value == val.startingBalance) {
                                  return;
                                } else if (e.target.value === "") {
                                  e.target.value = 0;
                                }
                                handleFieldChange(e, index, "startingBalance");
                              }}
                              sx={{ width: 100 }}
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="dollar"
                                  >
                                    <img
                                      className="pencilIcon"
                                      src={PencilIcon}
                                      alt=""
                                    />
                                  </InputAdornment>
                                ),
                                disableUnderline: true,
                              }}
                            />
                          </div>
                          <div className="head">Monthly Addition</div>
                          <div className="data monthly-addition">
                            <TextField
                              className="editable"
                              variant="standard"
                              defaultValue={val.monthlyAddition}
                              disabled={!val.selectedAccountId}
                              onBlur={(e) => {
                                if (e.target.value == val.monthlyAddition) {
                                  return;
                                } else if (e.target.value === "") {
                                  e.target.value = 0;
                                }
                                handleFieldChange(e, index, "monthlyAddition");
                              }}
                              name={"monthlyAddition"}
                              sx={{ width: 100 }}
                              InputProps={{
                                inputComponent: NumericFormatCustom,
                                endAdornment: (
                                  <InputAdornment
                                    position="start"
                                    className="dollar"
                                  >
                                    <img
                                      className="pencilIcon"
                                      src={PencilIcon}
                                      alt=""
                                    />
                                  </InputAdornment>
                                ),
                                disableUnderline: true,
                              }}
                            />
                          </div>
                          <div className="head">Rate Of Return</div>
                          <div className="data rate-of-return">
                            <TextField
                              className="editable"
                              variant="standard"
                              disabled={!val.selectedAccountId}
                              defaultValue={val.rateOfReturn || 0}
                              onBlur={(e) => {
                                if (e.target.value == val.rateOfReturn) {
                                  return;
                                } else if (e.target.value === "") {
                                  e.target.value = 0;
                                }
                                handleFieldChange(e, index, "rateOfReturn");
                              }}
                              inputMode="decimal"
                              sx={{
                                width: 90,
                                input: {
                                  textAlign: "center",
                                },
                              }}
                              InputProps={{
                                inputComponent: PercentFormatCustom,
                                endAdornment: (
                                  <InputAdornment position="start">
                                    <img
                                      className="pencilIcon"
                                      src={PencilIcon}
                                      alt=""
                                    />
                                  </InputAdornment>
                                ),
                                disableUnderline: true,
                              }}
                            />
                          </div>
                          <div className="head">Start Date</div>
                          <div className="data start-date">
                            <FormControl
                              variant="standard"
                              size="small"
                              sx={{ width: 44 }}
                            >
                              {!val.startMonth && (
                                <label
                                  id={`month-label-start-${index}`}
                                  className="monthLabel"
                                  style={{
                                    position: "absolute",
                                    top: -2,
                                    opacity: 0.8,
                                    color: "#858585",
                                  }}
                                >
                                  MM
                                </label>
                              )}
                              <Select
                                value={val.startMonth}
                                disabled={!val.selectedAccountId}
                                className="monthField"
                                labelId={`month-label-start-${index}`}
                                onChange={(e) => {
                                  handleFieldChange(e, index, "startMonth");
                                }}
                              >
                                {monthNames.map((month, index) => (
                                  <MenuItem key={index} value={index + 1} disabled={!isValidGoalMonth(index+1, "startMonth", val)}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                            <Tooltip
                              title={(() => {
                                if (val.endYear) {
                                  return `Enter value b/w ${yearFrom} - ${val.endYear}`;
                                } else {
                                  return `Enter value b/w ${yearFrom} - ${yearTo}`;
                                }
                              })()}
                            >
                              <TextField
                                placeholder="YYYY"
                                variant="standard"
                                defaultValue={val.startYear}
                                disabled={!val.selectedAccountId}
                                onChange={(e) => {
                                  validateYear(e, val.endYear, 0);
                                }}
                                onBlur={(e) => {
                                  if (e.target.value == val.startYear) {
                                    return;
                                  } else if (e.target.value === "") {
                                    e.target.style.color = "#000";
                                  } else if (
                                    !validateYearOnly(
                                      e.target.value,
                                      val.endYear,
                                      0
                                    )
                                  ) {
                                    e.target.value = val.startYear;
                                    e.target.style.color = "#000";
                                    return;
                                  }
                                  handleFieldChange(e, index, "startYear");
                                }}
                                sx={{ width: 44 }}
                                size="small"
                                type="tel"
                                inputProps={{ maxLength: 4 }}
                              />
                            </Tooltip>
                          </div>
                          <div className="head">End Date</div>
                          <div className="data end-date">
                            <FormControl
                              variant="standard"
                              size="small"
                              sx={{ width: 44 }}
                            >
                              {!val.endMonth && (
                                <label
                                  id={`month-label-end-${index}`}
                                  className="monthLabel"
                                  style={{
                                    position: "absolute",
                                    top: -2,
                                    opacity: 0.8,
                                    color: "#858585",
                                  }}
                                >
                                  MM
                                </label>
                              )}
                              <Select
                                className="monthField endMonth"
                                labelId={`month-label-end-${index}`}
                                value={val.endMonth}
                                disabled={!val.selectedAccountId}
                                onChange={(e) => {
                                  handleFieldChange(e, index, "endMonth");
                                }}
                              >
                                {monthNames.map((month, index) => (
                                  <MenuItem key={index} value={index + 1} disabled={!isValidGoalMonth(index+1, "endMonth", val)}>
                                    {month}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <Tooltip
                              title={(() => {
                                if (val.startYear) {
                                  return `Enter value b/w ${val.startYear} - ${yearTo}`;
                                } else {
                                  return `Enter value b/w ${yearFrom} - ${yearTo}`;
                                }
                              })()}
                            >
                              <TextField
                                placeholder="YYYY"
                                variant="standard"
                                defaultValue={val.endYear}
                                disabled={!val.selectedAccountId}
                                onChange={(e) => {
                                  validateYear(e, val.startYear, 1);
                                }}
                                onBlur={(e) => {
                                  if (e.target.value == val.endYear) {
                                    return;
                                  } else if (e.target.value === "") {
                                    e.target.style.color = "#000";
                                  } else if (
                                    !validateYearOnly(
                                      e.target.value,
                                      val.startYear,
                                      1
                                    )
                                  ) {
                                    e.target.value = val.endYear;
                                    e.target.style.color = "#000";
                                    return;
                                  }
                                  handleFieldChange(e, index, "endYear");
                                }}
                                type="tel"
                                sx={{ width: 44 }}
                                size="small"
                                inputProps={{ maxLength: 4 }}
                              />
                            </Tooltip>
                          </div>
                          <div className="head">Contribution</div>
                          <div className="data total-contribution">
                            {usdFormate(val.contribution)}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
          {!dataDashboard.fundingAccounts.length && (
            <Alert severity="warning">
              Account is needed for achieving the desired goal
            </Alert>
          )}
          {isMobile && (
            <div onClick={addData} className="mob-addContributionBtn">
              <img src={AddIcon} alt="" /> Add Contribution
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Financial;
