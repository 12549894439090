import React, { useState } from "react";
import { Box, LinearProgress, Typography, useMediaQuery } from "@mui/material";
import KeywordsDetails from "./KeywordsDetails";
import KeywordsFinancial from "./KeywordsFinancial";
import useDashboard from "../useDashboard";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";

import ArrowIcon from "../../assets/images/arrow-down.svg";
import ArrowActiveIcon from "../../assets/images/arrow-up-active.svg";
import PencilIcon from "../../assets/images/pencil2.svg";
import DeleteIcon from "../../assets/images/delete.svg";
import { useSetRecoilState } from "recoil";
import {
  atm_editGoal,
  atm_financialData,
  atm_financialTextData,
  atm_goalSection,
  atm_goalsModal,
  atm_selectedDetailsData,
  atm_selectedGeneralData,
  atm_selectedGoal,
} from "src/state/atomDashboard";
import DeleteGoalBox from "./DeleteGoalBox";

const getMonthName = (monthNumber) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  return monthNames[monthNumber - 1];
};

const GoalsInfo = () => {
  const { dataDashboard, fnsDashboard } = useDashboard();
  const [activeDropdowns, setActiveDropdowns] = useState([]);
  const setSelectedGoal = useSetRecoilState(atm_selectedGoal);
  const setSection = useSetRecoilState(atm_goalSection);
  const setGoalModal = useSetRecoilState(atm_goalsModal);
  const setEditGoal = useSetRecoilState(atm_editGoal);
  const setDetailsData = useSetRecoilState(atm_selectedDetailsData);
  const setFinancialTextData = useSetRecoilState(atm_financialTextData);
  const setFinancialData = useSetRecoilState(atm_financialData);
  const setGeneralData = useSetRecoilState(atm_selectedGeneralData);
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [isDeleteGoal, setIsDeleteGoal] = useState(false);

  const handleDropdown = (index, id) => {
    const isViewMoreExpanded = activeDropdowns.includes(index);

    if (!isViewMoreExpanded) {
      setActiveDropdowns([...activeDropdowns, index]);
      if (
        dataDashboard.allGoals[index]?.subMilestones === undefined &&
        !dataDashboard.activitiesLoading
      ) {
        fnsDashboard.getGoalActivities(index, id);
      }
    } else {
      setActiveDropdowns(activeDropdowns.filter((i) => i !== index));
    }
  };

  const handleEdit = (data, section, index) => {
    setSection(section);
    setEditGoal(true);
    setSelectedGoal(data);

    fnsDashboard.getGoalActivities(index, data.goalId);
    if (data.generalSection.specificGoalType) {
      fnsDashboard.getAllActionTypes(data.generalSection.actionId);
      fnsDashboard.getDegreeDuration(data.collegeDetailsSection.degreeType);
      fnsDashboard.getSchoolTypes(data.collegeDetailsSection.degreeType);
      fnsDashboard.getCollegeTypes(data.collegeDetailsSection.degreeType);

      setGeneralData((prevData) => ({
        ...prevData,
        option: data.generalSection.specificGoalType,
        familyMembers: Object.keys(data.generalSection.subjects).map(Number),
        action: data.generalSection.actionId,
        aim: data.generalSection.goalTypeId,
        month: data.generalSection.goalByMonth,
        year: data.generalSection.goalByYear,
        actionText: data.generalSection.goalDescription,
      }));
      setDetailsData({
        degree: data.collegeDetailsSection.degreeType,
        duration: data.collegeDetailsSection.degreeDuration,
        schoolType: data.collegeDetailsSection.collegeType,
        collegeType: data.collegeDetailsSection.collegeLocationType,
      });
      setFinancialTextData({
        total: data.collegeFinancialsSection.estimatedCollegeCost,
        scholarships: data.collegeFinancialsSection.fundingViaScholarships,
        savings: data.collegeFinancialsSection.fundingViaSavings,
        remaining: data.collegeFinancialsSection.fundingViaLoans,
      });
      setFinancialData(
        data.collegeFinancialsSection.fundingAccountDetails.map((data) => {
          return {
            sourceAccountId: data.sourceAccountId,
            selectedAccountId: data.userItemAccountId,
            startingBalance: data.startingBalance,
            monthlyAddition: data.monthlyContribution,
            rateOfReturn: data.rateOfReturn,
            startMonth: data.startMonth,
            startYear: data.startYear == 0 ? "" : data.startYear,
            endMonth: data.endMonth,
            endYear: data.endYear == 0 ? "" : data.endYear,
            contribution: data.projectedBalance,
            status: data.status,
          };
        })
      );
    } else {
      setGeneralData((prevData) => ({
        ...prevData,
        option: data.generalSection.specificGoalType,
        familyMembers: Object.keys(data.generalSection.subjects).map(Number),
        month: data.generalSection.goalByMonth,
        year: data.generalSection.goalByYear,
        actionText: data.generalSection.goalDescription,
      }));
      setDetailsData({
        amount: data.freeFormDetailsSection.goalTargetAmount,
        month:
          data.freeFormDetailsSection.goalEndByMonth ==
          data.generalSection.goalByMonth
            ? ""
            : data.freeFormDetailsSection.goalEndByMonth,
        year:
          data.freeFormDetailsSection.goalEndByYear ==
          data.generalSection.goalByYear
            ? ""
            : data.freeFormDetailsSection.goalEndByYear,
        drawnBy:
          data.generalSection.goalByMonth ==
            data.freeFormDetailsSection.goalEndByMonth &&
          data.generalSection.goalByYear ==
            data.freeFormDetailsSection.goalEndByYear
            ? true
            : false,
      });
      setFinancialData(
        data.freeFormFinancialsSection.fundingAccountDetails.map((data) => {
          return {
            sourceAccountId: data.sourceAccountId,
            selectedAccountId: data.userItemAccountId,
            startingBalance: data.startingBalance,
            monthlyAddition: data.monthlyContribution,
            rateOfReturn: data.rateOfReturn,
            startMonth: data.startMonth,
            startYear: data.startYear == 0 ? "" : data.startYear,
            endMonth: data.endMonth,
            endYear: data.endYear == 0 ? "" : data.endYear,
            contribution: data.projectedBalance,
            status: data.status,
          };
        })
      );
    }
    setGoalModal(true);
  };

  const handleDelete = (data) => {
    setSelectedGoal(data);
    setIsDeleteGoal(true);
  };

  return (
    <>
      <div className="goalsInfo scroller">
        {!isMobile && (
          <>
            <div className="infoHeader">General</div>
            <div className="infoHeader">Details</div>
            <div className="infoHeader">Financial Information</div>
          </>
        )}

        <div className="goalsInfoInside ">
          {dataDashboard.activitiesLoading && (
            <Box sx={{ width: "100%" }}>
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: -58,
                  left: 0,
                  width: "100%",
                }}
              />
            </Box>
          )}
          <div className="goalsInfoScroller scroller">
            {dataDashboard.allGoals.map((data, index) => {
              const monthName = getMonthName(data.generalSection.goalByMonth);

              if (data.generalSection.specificGoalType) {
                return (
                  <React.Fragment key={`fragment-${index}`}>
                    <div className="timeline-container keywords-container">
                      <Timeline sx={{ p: 0, height: "100%" }}>
                        <TimelineItem key={"timeline-" + index}>
                          <TimelineOppositeContent
                            sx={{ minWidth: 75 }}
                            className="milestoneDate"
                          >
                            {`${monthName || null} ${
                              data.generalSection.goalByYear || null
                            }`}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot className="dot done" />
                            {index !== dataDashboard.allGoals?.length - 1 && <TimelineConnector className="connector done" />}
                          </TimelineSeparator>
                          <TimelineContent className="milestoneText">
                            <div
                              className="goalName"
                              onClick={() => {
                                handleDropdown(index, data.goalId);
                              }}
                            >
                              {activeDropdowns.includes(index) ? (
                                <img src={ArrowActiveIcon} alt="" />
                              ) : (
                                <img src={ArrowIcon} alt="" />
                              )}
                              {`${data.generalSection.actionName} ${data.generalSection.goalTypeName}`}
                              <div className="subjects">
                                for [
                                {Object.values(
                                  data.generalSection.subjects
                                ).join(", ")}
                                ]
                              </div>
                            </div>
                            <span className="editIcons">
                              <img
                                onClick={() => handleEdit(data, 1, index)}
                                className="pencil"
                                src={PencilIcon}
                                alt=""
                              />
                              <img
                                onClick={() => handleDelete(data)}
                                className="delete"
                                src={DeleteIcon}
                                alt=""
                              />
                            </span>

                            {isMobile && (
                              <div className="keywords-box">
                                <div className="mobilekeyword-header">
                                  Details
                                </div>
                                <div className="details-container keywords-container">
                                  <KeywordsDetails
                                    key={`details-${index}`}
                                    data={data.collegeDetailsSection}
                                    handleEdit={handleEdit}
                                    allData={data}
                                  />
                                </div>

                                <div className="mobilekeyword-header">
                                  Financial Information
                                </div>
                                <div className="financial-container keywords-container">
                                  <KeywordsFinancial
                                    key={`financial-${index}`}
                                    data={data.collegeFinancialsSection}
                                    handleEdit={handleEdit}
                                    allData={data}
                                  />
                                </div>
                              </div>
                            )}
                            {activeDropdowns.includes(index) &&
                              data.subMilestones?.length > 0 &&
                              data.subMilestones.map((subData) => {
                                return (
                                  <Typography>
                                    <div className="date">
                                      <span>{subData.activityDate}</span>
                                      <span>
                                        {getMonthName(subData.activityMonth)}
                                      </span>
                                      <span>{subData.activityYear}</span>
                                    </div>
                                    <div className="title">
                                      {subData.activityName}
                                    </div>
                                  </Typography>
                                );
                              })}
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </div>

                    {!isMobile && (
                      <>
                        <div className="details-container keywords-container">
                          <KeywordsDetails
                            key={`details-${index}`}
                            data={data.collegeDetailsSection}
                            handleEdit={handleEdit}
                            allData={data}
                          />
                        </div>

                        <div className="financial-container keywords-container">
                          <KeywordsFinancial
                            key={`financial-${index}`}
                            data={data.collegeFinancialsSection}
                            handleEdit={handleEdit}
                            allData={data}
                          />
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={`fragment-${index}`}>
                    <div className="timeline-container keywords-container">
                      <Timeline sx={{ p: 0, height: "100%" }}>
                        <TimelineItem key={"timeline-" + index}>
                          <TimelineOppositeContent
                            sx={{ minWidth: 75 }}
                            className="milestoneDate"
                          >
                            {`${monthName || null} ${
                              data.generalSection.goalByYear || null
                            }`}
                          </TimelineOppositeContent>
                          <TimelineSeparator>
                            <TimelineDot className="dot done" />
                            {index !== dataDashboard.allGoals?.length - 1 && <TimelineConnector className="connector done" />}
                          </TimelineSeparator>
                          <TimelineContent className="milestoneText">
                            <div
                              className="goalName"
                              onClick={() => {
                                handleDropdown(index, data.goalId);
                              }}
                            >
                              {activeDropdowns.includes(index) ? (
                                <img src={ArrowActiveIcon} alt="" />
                              ) : (
                                <img src={ArrowIcon} alt="" />
                              )}
                              {data.generalSection.goalDescription}
                              <div className="subjects">
                                for [
                                {Object.values(
                                  data.generalSection.subjects
                                ).join(", ")}
                                ]
                              </div>
                            </div>
                            <span className="editIcons">
                              <img
                                onClick={() => handleEdit(data, 1, index)}
                                className="pencil"
                                src={PencilIcon}
                                alt=""
                              />
                              <img
                                onClick={() => handleDelete(data)}
                                className="delete"
                                src={DeleteIcon}
                                alt=""
                              />
                            </span>

                            {isMobile && (
                              <div className="keywords-box">
                                <div className="mobilekeyword-header">
                                  Details
                                </div>
                                <div className="details-container keywords-container">
                                  <KeywordsDetails
                                    key={`details-${index}`}
                                    data={data.freeFormDetailsSection}
                                    handleEdit={handleEdit}
                                    allData={data}
                                  />
                                </div>

                                <div className="mobilekeyword-header">
                                  Financial Information
                                </div>
                                <div className="financial-container keywords-container">
                                  <KeywordsFinancial
                                    key={`financial-${index}`}
                                    data={data.freeFormFinancialsSection}
                                    handleEdit={handleEdit}
                                    allData={data}
                                  />
                                </div>
                              </div>
                            )}
                            {activeDropdowns.includes(index) &&
                              data.subMilestones?.length > 0 &&
                              data.subMilestones.map((subData) => {
                                return (
                                  <Typography>
                                    <div className="date">
                                      <span>{subData.activityDate}</span>
                                      <span>
                                        {getMonthName(subData.activityMonth)}
                                      </span>
                                      <span>{subData.activityYear}</span>
                                    </div>
                                    <div className="title">
                                      {subData.activityName}
                                    </div>
                                  </Typography>
                                );
                              })}
                          </TimelineContent>
                        </TimelineItem>
                      </Timeline>
                    </div>

                    {!isMobile && (
                      <>
                        <div className="details-container keywords-container">
                          <KeywordsDetails
                            key={`details-${index}`}
                            data={data.freeFormDetailsSection}
                            handleEdit={handleEdit}
                            allData={data}
                          />
                        </div>

                        <div className="financial-container keywords-container">
                          <KeywordsFinancial
                            key={`financial-${index}`}
                            data={data.freeFormFinancialsSection}
                            handleEdit={handleEdit}
                            allData={data}
                          />
                        </div>
                      </>
                    )}
                  </React.Fragment>
                );
              }
            })}
          </div>
        </div>
      </div>
      {isDeleteGoal && (
        <DeleteGoalBox
          isDeleteGoal={isDeleteGoal}
          setIsDeleteGoal={setIsDeleteGoal}
        />
      )}
    </>
  );
};

export default GoalsInfo;
