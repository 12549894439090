export const validateDay = (e) => {
    let inputValue = e.target.value;

    if (isNaN(inputValue) || inputValue < 1 || inputValue > 31) {
        e.target.value = null;
        return;
    }
}
export const validateYear = (e, compareValue, field) => {
    let inputValue = isNaN(e) ? e.target.value : e;

    if (inputValue === "") {
        e.target.style.color = "#000";
        return false;
    }
    let currentYear = new Date().getFullYear();
    let lowerLimit = currentYear - 100;
    let upperLimit = currentYear + 80;

    if (isNaN(inputValue)) {
        e.target.value = null;
        return false;
    }

    if ((inputValue > compareValue && !field && compareValue !== "") || (inputValue < compareValue && field && compareValue !== "") || (inputValue <= lowerLimit) || (inputValue >= upperLimit)) {
        e.target.style.color = "#FF0000";
        return false;
    } else {
        e.target.style.color = "#000";
        return true;

    }
}

export const validateYearOnly = (value, compareValue, field) => {
    let year = new Date().getFullYear();
    let upper = year + 80;
    let lower = year - 100;

    if (value <= lower || value >= upper || (value > compareValue && !field && compareValue !== "") || (value < compareValue && field && compareValue !== "")) {
        return false;
    }
    return true;
}

export const validateMonthOnly = (e, data) => {
    const { startYear, endYear, startMonth, endMonth } = data;
    const inputMonth = e.target.value;
    const currentYear = new Date().getFullYear();



    if (startYear === currentYear) {
        return false;
    } else if (endYear && (startYear >= endYear)) {
        return false;
    }
    else if (startYear && (endYear <= startYear)) {
        return false;
    }
    else if (startYear && endYear && (startYear === endYear && inputMonth > endMonth)) {
        return false;
    } else if (startYear && endYear && (startYear === endYear && inputMonth < startMonth)) {
        return false;
    }

    return true;
};

export function isValidGoalMonth(monthNumber, field, data){
    const { startYear, endYear, startMonth, endMonth } = data;

    if(startYear == endYear && field === "startMonth" && monthNumber > endMonth) {
        return false;
    }
    if(startYear == endYear && field === "endMonth" && monthNumber < startMonth) {
        return false;
    }
    return true;
}