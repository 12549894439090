import React, { useState } from "react";
import { useRecoilState } from "recoil";
import KmnModal from "src/components/shared/KmnModal";
import {
  atm_selectedTransactionToAddComment,
} from "src/state/atomsTransactionData";
import useTransactionsLanding from "../useTransactionsLanding";

const CommentModal = ({comment}) => {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const [commentValue, setCommentValue] = useState(comment)
  const [selectedTransactionToAddComment, setSelectedTransactionToAddComment] = useRecoilState(atm_selectedTransactionToAddComment);
  const trimmedCommentValue = commentValue?.trim();

  const handleValueChange = (e) => {
    setCommentValue(e?.target?.value);
    fnsTransactions.resetTransactionCommentError();
  }

  const handleSave = async () => {
    await fnsTransactions.addTransactionComment({transactionId: selectedTransactionToAddComment, comments: trimmedCommentValue})
    setSelectedTransactionToAddComment(null);
  }

  return (
    <div onClick={e => e?.stopPropagation()}>
      <KmnModal
        className="commentModal"
        isOpen={true}
        onClose={() => setSelectedTransactionToAddComment(null)}
        onCancel={() => setSelectedTransactionToAddComment(null)}
        disableConfirm={dataTransactions.saveTransactionCommentLoading}
        loading={dataTransactions.saveTransactionCommentLoading}
        onConfirm={handleSave}
        hideHeader={true}
        actionPosition="right"
        btnVariant="secondary_small"
      >
        <h3>{comment ? "Edit comment" : "Add a comment"}</h3>
        <textarea
          defaultValue={commentValue}
          onChange={handleValueChange}
          placeholder="write your comment ..."
        />
      </KmnModal>
    </div>
  );
};

export default CommentModal;
