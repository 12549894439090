import { Box, Modal } from "@mui/material";
import React from "react";
import KmnButton from "../Accounts/Transactions/KmnButton";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import Loader from "./Loader";
import ErrorIcon from "@mui/icons-material/Error";

function getActionPosition(position) {
  switch (position) {
    case "center":
      return "center";
    case "left":
      return "flex-start";
    case "right":
      return "flex-end";
    default:
      return "flex-end";
  }
}

const KmnModal = (props) => {
  const {
    isOpen,
    onClose,
    title,
    subTitle,
    body,
    footer,
    onCancel,
    discardBtnLabel = "Cancel",
    confirmBtnLabel = "Save",
    cancelBtnColor,
    confirmBtnColor,
    onConfirm,
    className,
    btnVariant = "primary",
    width,
    disableFooterActions,
    hideConfirm,
    hideCancel,
    footerDivider = false,
    disableFooter = false,
    disableConfirm = false,
    disableCancel = false,
    loading = false,
    error = false,
    bodyStyle = {},
    footerRef,
    hideHeader = false,
    children,
    actionPosition,
    ...otherProps
  } = props;
  return (
    <Modal open={isOpen} onClose={onClose} {...otherProps}>
      <Box className={`kmnModal ${className}`} sx={{ width: width || 450 }}>
        {!hideHeader && (
          <div className="head">
            <div className="title">{title}</div>
            {subTitle && <div className="subTitle">{subTitle}</div>}
            <CloseRoundedIcon
              className="closeIcon"
              sx={{ fontWeight: 900 }}
              onClick={onClose}
            />
          </div>
        )}
        <div className="bodyWrapper">
          <Loader visible={loading} />
          {error && (
            <p className="error">
              <ErrorIcon /> <span>{error}</span>
            </p>
          )}
          <div className="body" style={{ ...bodyStyle }}>
            <>
              {body}
              {children}
            </>
          </div>
        </div>
        {footerDivider && <hr />}
        {!disableFooter && (
          <div
            className="footer"
            style={{
              justifyContent: getActionPosition(actionPosition),
            }}
            ref={footerRef}
          >
            {footer}
            {!disableFooterActions && (
              <div className="btns">
                {!hideCancel && (
                  <KmnButton
                    variant={btnVariant}
                    color={cancelBtnColor || "primary_outlined"}
                    onClick={onCancel}
                    isDisabled={disableCancel}
                  >
                    {discardBtnLabel}
                  </KmnButton>
                )}
                {!hideConfirm && (
                  <KmnButton
                    variant={btnVariant}
                    color={confirmBtnColor || "primary"}
                    onClick={onConfirm}
                    isDisabled={disableConfirm}
                  >
                    {confirmBtnLabel}
                  </KmnButton>
                )}
              </div>
            )}
          </div>
        )}
      </Box>
    </Modal>
  );
};

export default KmnModal;
