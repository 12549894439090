export const getGoalStartDate = (endMonth, endYear) => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  if (
    endYear > currentYear ||
    (endYear === currentYear && endMonth > currentMonth)
  ) {
    return { startMonth: currentMonth, startYear: currentYear };
  } else {
    return { startMonth: endMonth, startYear: endYear };
  }
};
