import axios from "axios";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_accountContribution,
  atm_activitiesLoading,
  atm_allGoals,
  atm_collegeTypes,
  atm_dateFilterNetWorth,
  atm_degreeDuration,
  atm_degreeTypes,
  atm_expenseComparisonTrend,
  atm_expenseComparisonTrendLoading,
  atm_familyMembers,
  atm_filterMonthExpense,
  atm_financialData,
  atm_financialTextData,
  atm_financialTextDataSelector,
  atm_fundingAccounts,
  atm_goalActivities,
  atm_goalsAction,
  atm_goalsActionTypes,
  atm_goalsLoading,
  atm_goalsModal,
  atm_incomeExpense,
  atm_isGoalsModalSubmitted,
  atm_netWorthChartData,
  atm_netWorthChartLoading,
  atm_rateOfReturnLoading,
  atm_relationShipTypes,
  atm_schoolTypes,
  atm_selectedDetailsData,
  atm_selectedGeneralData,
} from "src/state/atomDashboard";
import { usdUnformat, getAuthToken } from "src/global/central";
import useApi from "src/global/useApi";

export default function useDashboard() {
  let data = {};

  const [goalsLoading, setGoalsLoading] = useRecoilState(atm_goalsLoading);
  data.goalsLoading = goalsLoading;

  const [activitiesLoading, setActivitiesLoading] = useRecoilState(
    atm_activitiesLoading
  );
  data.activitiesLoading = activitiesLoading;

  const [goalsAction, setGoalsAction] = useRecoilState(atm_goalsAction);
  data.goalsAction = goalsAction;

  const [goalsActionTypes, setGoalsActionTypes] =
    useRecoilState(atm_goalsActionTypes);
  data.goalsActionTypes = goalsActionTypes;

  const [degreeTypes, setDegreeTypes] = useRecoilState(atm_degreeTypes);
  data.degreeTypes = degreeTypes;

  const [degreeDuration, setDegreeDuration] =
    useRecoilState(atm_degreeDuration);
  data.degreeDuration = degreeDuration;

  const [schoolTypes, setSchoolTypes] = useRecoilState(atm_schoolTypes);
  data.schoolTypes = schoolTypes;

  const [collegeTypes, setCollegeTypes] = useRecoilState(atm_collegeTypes);
  data.collegeTypes = collegeTypes;

  const [familyMembers, setFamilyMembers] = useRecoilState(atm_familyMembers);
  data.familyMembers = familyMembers;

  const [relationShipTypes, setRelationShipTypes] = useRecoilState(
    atm_relationShipTypes
  );
  data.relationShipTypes = relationShipTypes;

  const [allGoals, setAllGoals] = useRecoilState(atm_allGoals);
  data.allGoals = allGoals;

  const [financialTextData, setFinancialTextData] = useRecoilState(
    atm_financialTextData
  );

  const [fundingAccounts, setFundingAccounts] =
    useRecoilState(atm_fundingAccounts);
  data.fundingAccounts = fundingAccounts;

  const [goalActivities, setGoalActivities] =
    useRecoilState(atm_goalActivities);
  data.goalActivities = goalActivities;

  const [incomeExpense, setIncomeExpense] = useRecoilState(atm_incomeExpense);
  data.incomeExpense = incomeExpense;

  const [expenseComparisonTrendData, setExpenseComparisonTrendData] = useRecoilState(atm_expenseComparisonTrend);
  data.expenseComparisonTrendData = expenseComparisonTrendData;

  const [netWorthChartData, setNetWorthChartData] = useRecoilState(atm_netWorthChartData);
  data.netWorthChartData = netWorthChartData;

  const [expenseComparisonTrendLoading, setExpenseComparisonTrendLoading] = useRecoilState(atm_expenseComparisonTrendLoading);
  data.expenseComparisonTrendLoading = expenseComparisonTrendLoading;

  const [netWorthChartLoading, setNetWorthChartLoading] = useRecoilState(atm_netWorthChartLoading);
  data.netWorthChartLoading = netWorthChartLoading;

  const accountContribution = useRecoilValue(atm_accountContribution);
  data.accountContribution = accountContribution;

  const financialTextDataSelector = useRecoilValue(
    atm_financialTextDataSelector
  );
  const selectedGeneralData = useRecoilValue(atm_selectedGeneralData);

  const selectedDetailsData = useRecoilValue(atm_selectedDetailsData);

  const goalsActivities = useRecoilValue(atm_goalActivities);

  const setGoalsModal = useSetRecoilState(atm_goalsModal);

  const [financialData, setFinancialData] = useRecoilState(atm_financialData);

  const setIsGoalsModalSubmitted = useSetRecoilState(atm_isGoalsModalSubmitted);

  const dateFilterNetWorth = useRecoilValue(atm_dateFilterNetWorth);
  
  const filterMonthExpense = useRecoilValue(atm_filterMonthExpense);

  const { callApi: callGetDefaultRoRForAccountSubAccount, data: defaultRorForAccountSubAccount } = useApi();
  data.defaultRorForAccountSubAccount = defaultRorForAccountSubAccount;

  const [rateOfReturnLoading, setRateOfReturnLoading] = useRecoilState(atm_rateOfReturnLoading)
  data.rateOfReturnLoading = rateOfReturnLoading;

  function getApi(endpoint) {
    return process.env.REACT_APP_GOAL_API_END_POINT + endpoint;
  }

  const getDefaultRoRForAccountSubAccount = async (data, index) => {
    try {
      setRateOfReturnLoading(true)
      const accountId = data?.selectedAccountId;
      const api = getApi("/goals/v1/getDefaultRoRForAccountSubAccount");
      const rateOfReturn = await callGetDefaultRoRForAccountSubAccount(api, {
        userItemAccountId: accountId
      })
      setFinancialData((prevData) =>
        prevData.map((item, i) =>
          i === index
            ? {
                ...item,
                rateOfReturn,
              }
            : item
        )
      );
  
      calculateAccountContribution({...data, rateOfReturn}, index)
    }catch{}finally {
      setRateOfReturnLoading(false)
    }
  }

  function getAllActions() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/metadata/v1/getAllActions";

    axios
      .post(api, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setGoalsAction(data);
          setGoalsLoading(false);
        }
      });
  }

  function getAllActionTypes(id) {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/metadata/v1/getAllGoalTypesForAction";

    axios
      .post(
        api,
        {
          actionId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setGoalsActionTypes(data);
          setGoalsLoading(false);
        }
      });
  }

  function getDegreeTypes() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/metadata/v1/getDegreeTypes";

    axios
      .post(api, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setDegreeTypes(data);
          setGoalsLoading(false);
        }
      });
  }

  function getDegreeDuration(id) {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/metadata/v1/getDurationsForDegree";

    axios
      .post(
        api,
        {
          previousSelectionId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setDegreeDuration(data);
          setGoalsLoading(false);
        }
      });
  }

  function getSchoolTypes(id) {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/metadata/v1/getSchoolTypes";

    axios
      .post(
        api,
        {
          previousSelectionId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setSchoolTypes(data);
          setGoalsLoading(false);
        }
      });
  }

  function getCollegeTypes(id) {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/metadata/v1/getSchoolLocationTypes";

    axios
      .post(
        api,
        {
          previousSelectionId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setCollegeTypes(data);
          setGoalsLoading(false);
        }
      });
  }

  function getFamilyMembers() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_USER_API_END_POINT + "/users/v1/getFamilyMembers";

    axios
      .post(api, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;

          setFamilyMembers(data);
          setGoalsLoading(false);
        }
      });
  }

  function getRelationshipTypes() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_USER_API_END_POINT +
      "/users/v1/getRelationshipType";

    axios
      .post(api, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setRelationShipTypes(data);
          setGoalsLoading(false);
        }
      });
  }

  function saveMyGoal(goalId) {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT + "/goals/college/v1/saveGoal";

    axios
      .post(
        api,
        {
          goalId: goalId ? goalId : -1,
          generalSection: {
            subjects: selectedGeneralData.familyMembers,
            specificGoalType: selectedGeneralData.option,
            actionId: selectedGeneralData.action,
            goalTypeId: selectedGeneralData.aim,
            goalByMonth: selectedGeneralData.month,
            goalByYear: selectedGeneralData.year,
          },
          detailsSection: {
            degreeType: selectedDetailsData.degree,
            degreeDuration: selectedDetailsData.duration,
            collegeType: selectedDetailsData.schoolType,
            collegeLocationType: selectedDetailsData.collegeType,
          },
          financialsSection: {
            estimatedCollegeCost: financialTextData.total,
            fundingViaSavings: financialTextData.savings,
            fundingViaScholarships: financialTextData.scholarships,
            fundingViaLoans: financialTextDataSelector.remaining,
            fundingAccountDetails: financialData.map((data) => ({
              status: data.status,
              userItemAccountId: data.selectedAccountId,
              sourceAccountId: data.sourceAccountId,
              startingBalance: usdUnformat(data.startingBalance),
              projectedBalance: data.contribution,
              monthlyContribution: usdUnformat(data.monthlyAddition),
              rateOfReturn: usdUnformat(data.rateOfReturn),
              startMonth: data.startMonth,
              startYear: data.startYear,
              endMonth: data.endMonth,
              endYear: data.endYear,
            })),
          },
          activitiesSection: goalsActivities.map((data) => ({
            status: data.status,
            activityId: data?.activityId ? data.activityId : -1,
            activityName: data.activityName,
            activityDate: data.activityDate,
            activityMonth: data.activityMonth,
            activityYear: data.activityYear,
          })),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        console.log(data);
        setGoalsLoading(false);
        getAllGoals();
        setIsGoalsModalSubmitted(1);
        setGoalsModal(false);
      })
      .catch((err) => {
        setGoalsLoading(false);
        setIsGoalsModalSubmitted(-1);
        console.log("error from server " + err);
      });
  }

  function getAllGoals() {
    setActivitiesLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT + "/goals/v1/getAllGoals";

    axios
      .post(api, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setAllGoals(data);
        }
        setActivitiesLoading(false);
      });
  }

  function getCostProjection() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/v1/getCostProjection";

    axios
      .post(
        api,
        {
          collegeStartYear: selectedGeneralData.year,
          degreeType: selectedDetailsData.degree,
          degreeDuration: selectedDetailsData.duration,
          collegeType: selectedDetailsData.schoolType,
          collegeLocationType: selectedDetailsData.collegeType,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setFinancialTextData((prevData) => ({ ...prevData, total: data }));
          setGoalsLoading(false);
        }
      });
  }

  function getFundingAccounts() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/v1/getFundingAccounts";

    axios
      .post(api, null, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setFundingAccounts(data);
          setGoalsLoading(false);
        }
      });
  }

  function calculateAccountContribution(values, index) {
    const {
      startingBalance,
      monthlyAddition,
      startMonth,
      startYear,
      endMonth,
      endYear,
      selectedAccountId,
      rateOfReturn
    } = values;

    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/college/v1/calculateAccountContribution";

    axios
      .post(
        api,
        {
          userItemAccountId: selectedAccountId,
          startingBalance: usdUnformat(startingBalance) || 0,
          monthlyContribution: usdUnformat(monthlyAddition) || 0,
          rateOfReturn: usdUnformat(rateOfReturn) || 0,
          startMonth: startMonth != 0 ? +startMonth : "",
          startYear: startYear != 0 ? +startYear : "",
          endMonth: endMonth != 0 ? +endMonth : "",
          endYear: endYear != 0 ? +endYear : "",
          goalStartMonth: +selectedGeneralData.month,
          goalStartYear: +selectedGeneralData.year,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setFinancialData((prevData) =>
            prevData.map((item, i) =>
              i === index
                ? {
                    ...item,
                    contribution: data,
                  }
                : item
            )
          );
          setGoalsLoading(false);
        }
      });
  }

  function getGoalActivities(index, id) {
    setActivitiesLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT + "/goals/v1/getGoalActivities";

    axios
      .post(
        api,
        {
          goalId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        if (res["data"]["success"] == false) {
          console.log("error from server " + res["data"]["responseMessage"]);
          throw res["data"]["responseMessage"];
        } else {
          let data = res.data.responseData;
          setAllGoals((prevData) => {
            const updatedData = [...prevData];
            updatedData[index] = { ...updatedData[index], subMilestones: data };
            return updatedData;
          });
          setGoalActivities(data);
          setActivitiesLoading(false);
        }
      });
  }

  function saveHousehold(newMember) {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_USER_API_END_POINT + "/users/v1/saveHousehold";

    axios
      .post(api, [newMember], {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + getAuthToken(),
        },
      })
      .then((res) => {
        let data = res.data.responseData;
        setFamilyMembers(data);
        setGoalsLoading(false);
      })
      .catch((err) => {
        setGoalsLoading(false);
        console.log("error from server " + err);
      });
  }

  function deleteGoal(id) {
    setGoalsLoading(true);

    let api = process.env.REACT_APP_USER_API_END_POINT + "/goals/v1/deleteGoal";

    axios
      .post(
        api,
        {
          goalId: id,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        console.log(data);
        getAllGoals();
        setGoalsLoading(false);
      })
      .catch((err) => {
        setGoalsLoading(false);
        console.log("error from server " + err);
      });
  }

  function saveFreeFormGoal(goalId) {
    setGoalsLoading(true);

    let api = process.env.REACT_APP_GOAL_API_END_POINT + "/goals/v1/saveGoal";

    axios
      .post(
        api,
        {
          goalId: goalId ? goalId : -1,
          generalSection: {
            subjects: selectedGeneralData.familyMembers,
            specificGoalType: selectedGeneralData.option,
            actionId: 0,
            goalTypeId: 0,
            goalDescription: selectedGeneralData.actionText,
            goalByMonth: selectedGeneralData.month,
            goalByYear: selectedGeneralData.year,
          },
          detailsSection: {
            goalEndByMonth:
              selectedDetailsData.month || selectedGeneralData.month,
            goalEndByYear: selectedDetailsData.year || selectedGeneralData.year,
            goalTargetAmount: selectedDetailsData.amount,
          },
          financialsSection: {
            fundingAccountDetails: financialData.map((data) => ({
              status: data.status,
              userItemAccountId: data.selectedAccountId,
              sourceAccountId: data.sourceAccountId,
              startingBalance: usdUnformat(data.startingBalance),
              projectedBalance: data.contribution,
              monthlyContribution: usdUnformat(data.monthlyAddition),
              rateOfReturn: usdUnformat(data.rateOfReturn),
              startMonth: data.startMonth,
              startYear: data.startYear,
              endMonth: data.endMonth,
              endYear: data.endYear,
            })),
          },
          activitiesSection: goalsActivities.map((data) => ({
            status: data.status,
            activityId: data?.activityId ? data.activityId : -1,
            activityName: data.activityName,
            activityDate: data.activityDate,
            activityMonth: data.activityMonth,
            activityYear: data.activityYear,
          })),
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        console.log(data);
        setGoalsLoading(false);
        getAllGoals();
        setIsGoalsModalSubmitted(1);
        setGoalsModal(false);
      })
      .catch((err) => {
        setGoalsLoading(false);
        setIsGoalsModalSubmitted(-1);
        console.log("error from server " + err);
      });
  }

  function getIncomeExpense() {
    setGoalsLoading(true);

    let api =
      process.env.REACT_APP_ACCOUNT_API_END_POINT +
      "/accounts/dashboard/v1/incomeexpense";

    axios
      .post(
        api,
        {
          noOfMonths: 12,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        setIncomeExpense(data);
        setGoalsLoading(false);
      })
      .catch((err) => {
        console.log("error from server " + err);
        setGoalsLoading(false);
      });
  }
  
  function getExpenseComparisonTrend() {
    setExpenseComparisonTrendLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/dashboard/v1/getExpenseComparisonTrend";
    
    axios
      .post(
        api,
        {
          trendType: filterMonthExpense,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        setExpenseComparisonTrendData(data);
      })
      .catch((err) => {
        console.log("error from server " + err);
      }).finally(() => {
        setExpenseComparisonTrendLoading(false);
      });
  }
  
  function getNetWorthData() {
    setNetWorthChartLoading(true);

    let api =
      process.env.REACT_APP_GOAL_API_END_POINT +
      "/goals/dashboard/v1/getNetWorth";
    
    axios
      .post(
        api,
        {
          duration: dateFilterNetWorth,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + getAuthToken(),
          },
        }
      )
      .then((res) => {
        let data = res.data.responseData;
        setNetWorthChartData(data);
      })
      .catch((err) => {
        console.log("error from server " + err);
      }).finally(() => {
        setNetWorthChartLoading(false);
      });
  }

  let fnsDashboard = {
    getAllActions,
    getAllActionTypes,
    getDegreeTypes,
    getDegreeDuration,
    getSchoolTypes,
    getCollegeTypes,
    getFamilyMembers,
    getRelationshipTypes,
    saveMyGoal,
    getAllGoals,
    getCostProjection,
    getFundingAccounts,
    calculateAccountContribution,
    getGoalActivities,
    saveHousehold,
    deleteGoal,
    saveFreeFormGoal,
    getIncomeExpense,
    getExpenseComparisonTrend,
    getNetWorthData,
    getDefaultRoRForAccountSubAccount
  };

  return { dataDashboard: data, fnsDashboard };
}
