import {
  Alert,
  Box,
  LinearProgress,
  Modal,
  Snackbar,
  useMediaQuery,
} from "@mui/material";
import React, { useCallback, useEffect } from "react";
import CloseIcon from "../../../assets/images/close.svg";
import ArrowLeftIcon from "../../../assets/images/arrow-left.svg";
import ArrowRightIcon from "../../../assets/images/arrow-right";
import General from "./General/General";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import {
  atm_degreeDuration,
  atm_collegeTypes,
  atm_degreeTypes,
  atm_goalsAction,
  atm_goalsActionTypes,
  atm_goalsModal,
  atm_selectedGeneralData,
  atm_schoolTypes,
  atm_selectedDetailsData,
  atm_financialData,
  atm_financialTextData,
  atm_goalActivities,
  atm_isGoalsModalSubmitted,
  atm_goalSection,
  atm_editGoal,
  atm_selectedGoal,
} from "src/state/atomDashboard";
import Details from "./Details/Details";
import Financial from "./Financial/Financial";
import Activities from "./Activities/Activities";
import useDashboard from "../../useDashboard";
import { validateYearOnly } from "src/validations/Goals";

const NewGoalModal = () => {
  const { dataDashboard, fnsDashboard } = useDashboard();
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [isModal, setIsModal] = useRecoilState(atm_goalsModal);
  const [editMode, setEditMode] = useRecoilState(atm_editGoal);
  const [section, setSection] = useRecoilState(atm_goalSection);

  const selectedGeneralData = useRecoilValue(atm_selectedGeneralData);
  const selectedFinancialData = useRecoilValue(atm_financialData);
  const selectedDetailsData = useRecoilValue(atm_selectedDetailsData);

  const [isGoalsModalSubmitted, setIsGoalModalSubmitted] = useRecoilState(
    atm_isGoalsModalSubmitted
  );
  const selectedGoal = useRecoilValue(atm_selectedGoal);

  const resetSection = useResetRecoilState(atm_goalSection);
  const resetSelectedGeneralData = useResetRecoilState(atm_selectedGeneralData);

  const resetActionData = useResetRecoilState(atm_goalsAction);
  const resetActionTypeData = useResetRecoilState(atm_goalsActionTypes);
  const resetDegreeTypes = useResetRecoilState(atm_degreeTypes);
  const resetDegreeDuration = useResetRecoilState(atm_degreeDuration);
  const resetSchoolTypes = useResetRecoilState(atm_schoolTypes);
  const resetCollegeTypes = useResetRecoilState(atm_collegeTypes);

  const resetSelectedDetailsData = useResetRecoilState(atm_selectedDetailsData);

  const resetFinancialData = useResetRecoilState(atm_financialData);
  const resetFinancialTextData = useResetRecoilState(atm_financialTextData);
  const resetGoalsActivities = useResetRecoilState(atm_goalActivities);

  const isGeneralFilled = () => {
    const { option, familyMembers, action, aim, month, year, actionText } =
      selectedGeneralData;

    if (
      familyMembers.length &&
      month &&
      year &&
      ((action && aim && option) || actionText.trim())
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isDetailsFilled = () => {
    const { degree, duration, schoolType, collegeType, drawnBy, month, year, amount } =
      selectedDetailsData;
    if (
      (degree && duration && schoolType && collegeType) ||
      (month && year && amount) ||
      (amount && drawnBy == true)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const isFinancialFilled = useCallback(() => {
    for (const financialRow of selectedFinancialData) {
      const {
        selectedAccountId,
        monthlyAddition,
        startMonth,
        startYear,
        endMonth,
        endYear,
      } = financialRow;

      if (!selectedAccountId && financialRow?.status === 0) {
        return false;
      } else if (
        monthlyAddition &&
        financialRow?.status === 0 &&
        (!startMonth || !startYear || !endMonth || !endYear)
      ) {
        return false;
      }
    }

    // All rows have account and startingBalance values
    return true;
  }, [selectedFinancialData]);
  const handleNext = () => {
    if (section < 4 && (isGeneralFilled() || isDetailsFilled())) {
      setSection((prev) => prev + 1);
    }
    return;
  };

  const handleBack = () => {
    if (section > 1) {
      setSection((prev) => prev - 1);
    }
  };

  const resetGoalModal = () => {
    setIsModal(false);
    setEditMode(false);
    resetSection();
    resetSelectedGeneralData();
    resetActionData();
    resetActionTypeData();
    resetDegreeTypes();
    resetDegreeDuration();
    resetSchoolTypes();
    resetCollegeTypes();
    resetSelectedDetailsData();
    resetFinancialData();
    resetFinancialTextData();
    resetGoalsActivities();
  };

  useEffect(() => {
    if (isModal) {
      fnsDashboard.getAllActions();
      fnsDashboard.getFamilyMembers();
      fnsDashboard.getRelationshipTypes();
      fnsDashboard.getDegreeTypes();
      fnsDashboard.getFundingAccounts();
    } else {
      resetGoalModal();
    }
  }, [isModal]);

  const handleSectionSwitch = (val) => {
    if (isGeneralFilled() && val <= 2) {
      setSection(val);
    } else if (isDetailsFilled() && val <= 4) {
      setSection(val);
    } else {
      return;
    }
  };

  const checkSectionFilled = () => {
    switch (section) {
      case 1:
        if (isGeneralFilled()) return true;
        break;
      case 2:
        if (isDetailsFilled()) return true;
        break;
      case 3:
        if (isFinancialFilled()) return true;
        break;
      case 4:
        return true;
      default:
        return false;
    }
  };

  const handleSave = () => {
    if (editMode) {
      selectedGeneralData.option
        ? fnsDashboard.saveMyGoal(selectedGoal.goalId)
        : fnsDashboard.saveFreeFormGoal(selectedGoal.goalId);
    } else {
      selectedGeneralData.option
        ? fnsDashboard.saveMyGoal()
        : fnsDashboard.saveFreeFormGoal();
    }
  };

  return (
    <>
      {isModal && <div className="backdrop"></div>}
      <Modal hideBackdrop open={isModal} onClose={() => setIsModal(false)}>
        <Box
          sx={{
            position: "relative",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: 1212,
            maxWidth: "95%",
            height: isMobile ? "95%" : 620,
          }}
        >
          <div className="newGoalModal">
            <ul className="sideOptions">
              <li
                className={`${section == 1 ? "active" : ""}`}
                onClick={() => handleSectionSwitch(1)}
              >
                General
              </li>
              <li
                className={`${section == 2 ? "active" : ""}`}
                onClick={() => handleSectionSwitch(2)}
              >
                Details
              </li>
              <li
                className={`${section == 3 ? "active" : ""}`}
                onClick={() => handleSectionSwitch(3)}
              >
                <p>
                  Fin<span className="pc">ancial</span> Info
                  <span className="pc">rmation</span>
                </p>
              </li>
              <li
                className={`${section == 4 ? "active" : ""}`}
                onClick={() => handleSectionSwitch(4)}
              >
                Activities
              </li>
            </ul>
            <div className="content">
              {(dataDashboard.goalsLoading || dataDashboard.rateOfReturnLoading) && (
                <Box sx={{ width: "100%" }}>
                  <LinearProgress
                    sx={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                    }}
                  />
                </Box>
              )}
              {!isMobile && (
                <img
                  className="closeGoalsModalBtn"
                  src={CloseIcon}
                  alt=""
                  onClick={resetGoalModal}
                />
              )}
              {section == 1 && <General />}
              {section == 2 && <Details />}
              {section == 3 && <Financial />}
              {section == 4 && <Activities />}

              <div className="buttons">
                {(section != 1 || isMobile) && (
                  <div className="backBtn btn mob-backBtn" onClick={handleBack}>
                    <img hidden={!isMobile} src={ArrowLeftIcon} alt="" />
                    Back
                  </div>
                )}
                {isMobile && (
                  <>
                    <button
                      disabled={
                        dataDashboard.goalsLoading ||
                        !checkSectionFilled() ||
                        !validateYearOnly(selectedGeneralData.year)
                      }
                      onClick={handleNext}
                      className="mob-nextBtn btn"
                    >
                      Next <ArrowRightIcon />
                    </button>
                    <button
                      onClick={resetGoalModal}
                      className="mob-cancelBtn btn"
                    >
                      Cancel
                    </button>
                  </>
                )}
                <div className="groupBtn">
                  <button
                    disabled={
                      dataDashboard.goalsLoading ||
                      !checkSectionFilled() ||
                      !validateYearOnly(selectedGeneralData.year) ||
                      (!validateYearOnly(selectedDetailsData.year) &&
                        !selectedDetailsData.drawnBy)
                    }
                    onClick={() => {
                      if (
                        dataDashboard.goalsLoading ||
                        !checkSectionFilled() ||
                        !validateYearOnly(selectedGeneralData.year) ||
                        (!validateYearOnly(selectedDetailsData.year) &&
                          !selectedDetailsData.drawnBy)
                      ) {
                        return;
                      } else {
                        handleSave(section);
                      }
                    }}
                    className="saveBtn btn"
                  >
                    Save
                  </button>
                  {section < 4 && !isMobile && (
                    <button
                      disabled={
                        dataDashboard.goalsLoading ||
                        !checkSectionFilled() ||
                        !validateYearOnly(selectedGeneralData.year) ||
                        (!validateYearOnly(selectedDetailsData.year) &&
                          !selectedDetailsData.drawnBy)
                      }
                      onClick={handleNext}
                      className="nextBtn btn"
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={Boolean(isGoalsModalSubmitted)}
        autoHideDuration={3000}
        onClose={() => setIsGoalModalSubmitted(0)}
      >
        <Alert
          onClose={() => setIsGoalModalSubmitted(0)}
          severity={Boolean(isGoalsModalSubmitted) == 1 ? "success" : "error"}
          sx={{ width: "100%" }}
        >
          {isGoalsModalSubmitted == 1
            ? "Success !"
            : isGoalsModalSubmitted == -1
            ? "Error !"
            : ""}
        </Alert>
      </Snackbar>
    </>
  );
};

export default NewGoalModal;
