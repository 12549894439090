import { atom, selector } from "recoil";

export const atm_goalsModal = atom({
  key: "goalsModal",
  default: false,
});

export const atm_goalsLoading = atom({
  key: "goalsLoading",
  default: false,
});

export const atm_activitiesLoading = atom({
  key: "activitiesLoading",
  default: false,
});

export const atm_editGoal = atom({
  key: "editGoal",
  default: false,
});

export const atm_goalSection = atom({
  key: "goalSection",
  default: 1,
});

export const atm_selectedGoal = atom({
  key: "selectedGoal",
  default: {},
});

export const atm_selectedGeneralData = atom({
  key: "selectedGeneralData",
  default: {
    option: true,
    familyMembers: [],
    action: "",
    aim: "",
    month: "",
    year: "",
    actionText: "",
  },
});

export const atm_familyMembers = atom({
  key: "familyMembers",
  default: [],
});

export const atm_goalsAction = atom({
  key: "goalsAction",
  default: [],
});

export const atm_goalsActionTypes = atom({
  key: "goalsActionTypes",
  default: [],
});

export const atm_degreeTypes = atom({
  key: "degreeTypes",
  default: [],
});

export const atm_degreeDuration = atom({
  key: "degreeDuration",
  default: [],
});

export const atm_schoolTypes = atom({
  key: "schoolTypes",
  default: [],
});

export const atm_collegeTypes = atom({
  key: "collegeTypes",
  default: [],
});

export const atm_selectedDetailsData = atom({
  key: "selectedDetailsData",
  default: {
    degree: "",
    duration: "",
    schoolType: "",
    collegeType: "",
    amount: "",
    drawnBy: true,
    month: "",
    year: "",
  },
});

export const atm_relationShipTypes = atom({
  key: "relationShipTypes",
  default: [],
});

export const atm_selectedFinancialStep = atom({
  key: "financialStep1",
  default: {
    option: 1,
    savings: "",
    scholarships: "",
    loan: "",
  },
});

export const atm_allGoals = atom({
  key: "allGoals",
  default: [],
});

export const atm_fundingAccounts = atom({
  key: "fundingAccounts",
  default: [],
});

export const atm_financialTextData = atom({
  key: "financialTextData",
  default: {
    total: 0,
    scholarships: 0,
    savings: 0,
    remaining: 0,
  },
});

export const atm_financialTextDataSelector = selector({
  key: "financialTextDataSelector",
  get: ({ get }) => {
    const { total, scholarships, savings } = get(atm_financialTextData);
    const remaining = total - scholarships - savings;

    return {
      total,
      scholarships,
      savings,
      remaining,
    };
  },
});

export const atm_financialData = atom({
  key: "financialData",
  default: [],
});

export const atm_accountContribution = atom({
  key: "accountContribution",
  default: [],
});

export const atm_goalActivities = atom({
  key: "goalActivities",
  default: [],
});

export const atm_isGoalsModalSubmitted = atom({
  key: "isGoalsModalSubmitted",
  default: 0,
});

export const atm_incomeExpense = atom({
  key: "incomeExpense",
  default: [],
});

export const atm_expenseComparisonTrend = atom({
  key: "expenseComparisonTrend",
  default: {},
});

export const atm_netWorthChartData = atom({
  key: "netWorthChartData",
  default: [],
});

export const atm_expenseComparisonTrendLoading = atom({
  key: "expenseComparisonTrendLoading",
  default: false,
});

export const atm_rateOfReturnLoading = atom({
  key: "rateOfReturnLoading",
  default: false,
});

export const atm_netWorthChartLoading = atom({
  key: "netWorthChartLoading",
  default: false,
});

export const atm_dateFilterNetWorth = atom({
  key : "dateFilterNetWorth",
  default: 12
})

export const atm_filterMonthExpense = atom({
  key : "filterMonthExpense",
  default: "0"
})