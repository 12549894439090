import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import FiltersMob from "../../assets/images/filters-mob.svg";
import useTransactionsLanding from "./useTransactionsLanding";
import TransactionChart from "./TransactionChart";
import { GetWindowWidth } from "../../../global/central";
import MobTransTable from "./MobTransTable";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_currentTransactionPage,
  atm_isAllSelectedInBulkEdit,
  atm_isBulkEditMode,
  atm_isEditSubCatModalOpen,
  atm_isManageRulesModal,
  atm_isManageTagsModal,
  atm_IsMobTransactionFilter,
  atm_selectedDataForBulkEdit,
  atm_transactionsUpdateLoading,
} from "src/state/atomsTransactionData";
import AccountNavigation from "../AccountNavigation";
import BulkEditBox from "./BulkEditBox";
import useDeviceType from "src/hooks/useDeviceType";
import EditCategoryModal from "../EditCategoryModal/EditCategoryModal";
import ManageTagsModal from "./ManageTagsModal";
import { Row } from "src/components/shared";
import KmnButton from "./KmnButton";
import ManageRulesModal from "../Rules/ManageRulesModal";
import InfiniteList from "./InfiniteList";

export const TrasactionRightSide = ({
  isTransactionListLoading,
  transactionsListTable,
  clmnTransactions,
  mobOptions,
  setMobOptions,
}) => {
  const parentScroller = useRef(null);
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const [dropdownActive, setdropdownActive] = useState(null);

  const setIsMobFilter = useSetRecoilState(atm_IsMobTransactionFilter);

  const initialRenderRef = useRef(true);
  
  const width = GetWindowWidth();
  const hasMoreData =
    transactionsListTable?.length < dataTransactions.allTransactionDataCount;
  const isBulkEditMode = useRecoilValue(atm_isBulkEditMode);
  const { isMobile } = useDeviceType();
  
  const [selectedDataForBulkEdit, setSelectedDataForBulkEdit] = useRecoilState(
    atm_selectedDataForBulkEdit
  );

  const isEditSubCatModalOpen = useRecoilValue(atm_isEditSubCatModalOpen);

  const isManageTagsModal = useRecoilValue(atm_isManageTagsModal);

  const [isManageRulesModal, setIsManageRulesModal] = useRecoilState(
    atm_isManageRulesModal
  );

  const isTransactionsUpdateLoading = useRecoilValue(atm_transactionsUpdateLoading)

  const triggerManageRulesModal = () => {
    setIsManageRulesModal(true);
  };

  const cancelManageRulesModal = () => {
    setIsManageRulesModal(false);
  };

  const handleRowSelection = (rows) => {
    setSelectedDataForBulkEdit((prevData) => ({
      ...prevData,
      transactionIds: rows,
    }));
  };

  useEffect(() => {
    if (!initialRenderRef.current) {
      fnsTransactions.getTransactionsData();
    } else {
      initialRenderRef.current = false;
    }
  }, []);

  const [currentPage, setCurrentPage] = useRecoilState(atm_currentTransactionPage);
  const isAllSelectedInBulkEdit = useRecoilValue(atm_isAllSelectedInBulkEdit)

  const loadMoreData = () => {
    if (!isTransactionListLoading) {
      setCurrentPage((prevPage) => prevPage + 1);
      fnsTransactions.getTransactionsData({pageNumber: currentPage + 1, fetchMore: true, isAllSelectedInBulkEdit});
    }
  };

  useLayoutEffect(() => {
    if(parentScroller?.current) {
      setCurrentPage(1);
      parentScroller?.current?.scroll(0,0);
    }
  }, [dataTransactions.isTransactionCountLoading, dataTransactions.addReviewLoading, isTransactionsUpdateLoading])

  return (
    <>
      {(dropdownActive === "second" ||
        dropdownActive === "first" ||
        dropdownActive === "third" ||
        dropdownActive === "fourth" ||
        dropdownActive === "fifth" ||
        dropdownActive === "sixth") && (
        <span
          className="background-bg"
          onClick={(e) => {
            setdropdownActive(null);
          }}
        ></span>
      )}

      <div className="transaction-right-cover">
        {width > 900 && (
          <Row justify="between">
            <AccountNavigation />
            <KmnButton
              variant="secondary"
              color="primary"
              onClick={triggerManageRulesModal}
            >
              Rules
            </KmnButton>
          </Row>
        )}
        <div
          hidden={width < 900 && mobOptions === 2}
          className="transaction-right-side"
        >
          {width < 900 && mobOptions === 3 && (
            <ul className="transaction-mob-options">
              <li
                className={mobOptions === 1 ? "active" : ""}
                onClick={() => {
                  setMobOptions(1);
                }}
              >
                All
              </li>
              <li
                className={mobOptions === 2 ? "active" : ""}
                onClick={() => {
                  setMobOptions(2);
                }}
              >
                My Accounts
              </li>
              <li
                className={mobOptions === 3 ? "active" : ""}
                onClick={() => {
                  setMobOptions(3);
                }}
              >
                Income/Expense Trend
              </li>
            </ul>
          )}
          <div className="transaction-right-inside">
            {(!isBulkEditMode || isMobile) && (
              <div className="top-area">
                <h1>
                  <span>Income/Expense Trend</span>
                  {width < 900 && (
                    <div className="filter-cover">
                      <img
                        onClick={() => setIsMobFilter(true)}
                        src={FiltersMob}
                        alt=""
                      />
                    </div>
                  )}
                </h1>
                <div className="right-top-area">
                  <div className="box-round">
                    <span className="blue-box box"></span>
                    <p>Income</p>
                  </div>
                  <div className="box-round">
                    <span className="red-box box"></span>
                    <p>Expense</p>
                  </div>
                  <div className="box-round">
                    <span className="yellow-box box"></span>
                    <p>Net cash flow</p>
                  </div>
                </div>
              </div>
            )}
            {isBulkEditMode && <BulkEditBox />}
            <div
              id="infinite-scroller"
              className="scrollable-content"
              ref={parentScroller}
            >
              <div className="scrollable-content-inside">
                {!isBulkEditMode && <TransactionChart />}

                {width < 900 ? (
                  <MobTransTable parentScroller={parentScroller} />
                ) : (
                  <div
                    className="transactionTable"
                    style={{
                      width: "100%",
                      height: isBulkEditMode ? "100%" : 100,
                      position: "relative",
                      marginTop: isBulkEditMode ? 0 : 20,
                    }}
                  >
                    {(isTransactionListLoading || isTransactionsUpdateLoading) && (
                      <Box sx={{ width: "100%" }}>
                        <LinearProgress
                          sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                          }}
                        />
                      </Box>
                    )}

                    <InfiniteList
                      columns={clmnTransactions}
                      rows={transactionsListTable}
                      onLoadMore={loadMoreData}
                      isLoading={dataTransactions.isTransactionListLoading}
                      hasMore={hasMoreData}
                      checkboxSelection={isBulkEditMode}
                      rowSelectionModel={
                        selectedDataForBulkEdit?.transactionIds || []
                      }
                      onRowSelectionModelChange={(newRowSelectionModal) => {
                        handleRowSelection(newRowSelectionModal);
                      }}
                      isRowSelectable={(params) => !params?.row?.pending}
                      getRowClassName={(params) => {
                        if (params?.row?.pending) {
                          return "pending";
                        }
                      }}
                      getRowHeight={() => "auto"}
                      currentPage={currentPage}
                      totalRows={dataTransactions.allTransactionDataCount}
                      scrollerRef={parentScroller}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {isEditSubCatModalOpen && <EditCategoryModal />}
      {isManageTagsModal && <ManageTagsModal />}
      {isManageRulesModal && (
        <ManageRulesModal cancelManageRulesModal={cancelManageRulesModal} />
      )}
    </>
  );
};
