import { Tooltip } from "@mui/material";
import React from "react";
import { useRecoilState } from "recoil";
import { EmptyMessageIcon, FilledMessageIcon } from "src/libs/icons";
import { atm_selectedTransactionToAddComment } from "src/state/atomsTransactionData";
import CommentModal from "./CommentModal";

const CommentBox = ({ data }) => {
  const [selectedTransactionToAddComment, setSelectedTransactionToAddComment] =
    useRecoilState(atm_selectedTransactionToAddComment);
  const transactionId = data?.id || data?.transactionId;
  const comment = data?.comments?.trim();

  const handleCommentModal = (e) => {
    e?.stopPropagation();
    setSelectedTransactionToAddComment(transactionId);
  };
  return (
    <>
      <div className="commentBox" style={{ height: 20, width: 20 }}>
        <Tooltip
          title={comment}
          placement="bottom-start"
          arrow
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [-10, 0],
                  },
                },
              ],
            },
          }}
          componentsProps={{
            tooltip: {
              className: "commentTooltip",
              sx: {
                boxShadow: "0 -1px 10px rgb(0 0 0/0.15)",
                backgroundColor: "white",
                color: "#000",
              },
            },
            arrow: {
              sx: {
                color: "#fff",
              },
            },
          }}
        >
          {!comment ? (
            <EmptyMessageIcon
              className="messageIcon"
              onClick={handleCommentModal}
            />
          ) : (
            <FilledMessageIcon
              className={`messageIcon ${comment && "filledMessageIcon"}`}
              onClick={handleCommentModal}
            />
          )}
        </Tooltip>
      </div>
      {transactionId === selectedTransactionToAddComment && (
        <CommentModal comment={comment} />
      )}
    </>
  );
};

export default CommentBox;
